import { Description } from '@gr4vy/poutine-react'

export interface NumericDescriptionProps {
  children?: string
}

export const NumericDescription = ({ children }: NumericDescriptionProps) => {
  return (
    <Description align="flex-end">
      <Description.Text
        style={{
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        {children ?? '-'}
      </Description.Text>
    </Description>
  )
}
