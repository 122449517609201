import { Heading, Text } from '@gr4vy/poutine-react'
import { ColumnDef, DataTable, TimeDate } from 'shared/components/DataTable'
import { Panel } from 'shared/components/Panel'
import currencyFormat from 'shared/helpers/currency-format'
import { useTransactionSettlements } from 'transactions/hooks/use-transaction-settlements'
import { Transaction, TransactionSettlement } from 'transactions/services'
import { NumericDescription } from './NumericDescription'

const columns: Array<ColumnDef<TransactionSettlement>> = [
  {
    id: 'currency',
    accessorKey: 'currency',
    size: 98,
    header: 'Currency',
  },
  {
    id: 'grossAmount',
    header: () => <Text textAlign="right">Gross amount</Text>,
    size: 128,
    cell: ({ row }) => {
      const formattedAmount = currencyFormat(
        row.original.amount + row.original.commission,
        {
          currency: row.original.currency,
        }
      )

      return <NumericDescription>{formattedAmount}</NumericDescription>
    },
  },
  {
    id: 'exchangeRante',
    size: 92,
    header: () => <Text textAlign="right">Exchange</Text>,
    cell: ({ row }) => {
      return (
        <NumericDescription>
          {String(row.original.exchangeRate) ?? '-'}
        </NumericDescription>
      )
    },
  },
  {
    id: 'markup',
    header: () => <Text textAlign="right">Markup</Text>,
    size: 140,
    cell: ({ row }) => {
      const formattedAmount = currencyFormat(row.original.markup, {
        currency: row.original.currency,
      })

      return <NumericDescription>{formattedAmount}</NumericDescription>
    },
  },
  {
    id: 'interchange',
    header: () => <Text textAlign="right">Interchange</Text>,
    size: 140,
    cell: ({ row }) => {
      const formattedAmount = currencyFormat(row.original.interchange, {
        currency: row.original.currency,
      })

      return <NumericDescription>{formattedAmount}</NumericDescription>
    },
  },
  {
    id: 'schemeFee',
    header: () => <Text textAlign="right">Scheme fee</Text>,
    size: 140,
    cell: ({ row }) => {
      const formattedAmount = currencyFormat(row.original.schemeFee, {
        currency: row.original.currency,
      })

      return <NumericDescription>{formattedAmount}</NumericDescription>
    },
  },
  {
    id: 'commission',
    header: () => <Text textAlign="right">Commission</Text>,
    size: 120,
    cell: ({ row }) => {
      const formattedAmount = currencyFormat(row.original.commission, {
        currency: row.original.currency,
      })

      return <NumericDescription>{formattedAmount}</NumericDescription>
    },
  },
  {
    id: 'amount',
    size: 128,

    header: () => <Text textAlign="right">Net amount</Text>,
    cell: ({ row }) => {
      const formattedAmount = currencyFormat(row.original.amount, {
        currency: row.original.currency,
      })

      return <NumericDescription>{formattedAmount}</NumericDescription>
    },
  },
  {
    id: 'postedAt',
    accessorKey: 'postedAt',
    header: 'Date/Time',
    size: 160,
    cell: function DateCell({ renderValue }) {
      const postedAt = renderValue<TransactionSettlement['postedAt']>()

      return <TimeDate value={postedAt} />
    },
  },
]

const SettlementsPanel = ({ transaction }: { transaction: Transaction }) => {
  const { settlements, isLoadingSettlements } =
    useTransactionSettlements(transaction)

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Settlement</Heading>
      </Panel.Header>
      <Panel.Content>
        <DataTable
          data={{ items: settlements || [] }}
          columns={columns}
          loading={isLoadingSettlements}
        />
      </Panel.Content>
    </Panel>
  )
}

export default SettlementsPanel
