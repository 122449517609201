import { useQuery } from '@tanstack/react-query'
import { LIST_SETTLEMENTS } from 'shared/constants/query-keys/transactions'
import { listTransactionSettlements, Transaction } from 'transactions/services'

export const useTransactionSettlements = (transaction: Transaction) => {
  const { data, isLoading } = useQuery({
    queryKey: [LIST_SETTLEMENTS, transaction.id],
    queryFn: () => listTransactionSettlements(transaction.id, { limit: 100 }),
    enabled: !!transaction?.id,
  })

  return {
    settlements: data?.items,
    isLoadingSettlements: isLoading,
  }
}
